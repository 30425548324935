// src/routes/AppRoutes.tsx

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/Home/Home";
import Questions from "../pages/Questionnaire/Questionnaire";
import Result from "../pages/Result/Result";
import PreQuestionnaire from "../pages/PreQuestionnaire/PreQuestionnaire";
import UpperBar from "../components/UpperBar";
import BottomBar from "../components/BottomBar";
import FetchUuid from "../components/FetchUuid";
import ProtectedRoute from "../components/ProtectedRoute";
import NoPage from "../pages/NogPage/NoPage";
import DashboardAdmin from "../pages/Dashboard/DashboardAdmin";
import Login from "../pages/Login/Login";
import CreateInterview from "../pages/CreateInterview/CreateInterview";
import NotFound from "../pages/NogPage/NoPage";
import ChatInterview from '../pages/ChatInterview/ChatInterview';

interface AppRoutesProps {
  hideQuestions: boolean;
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppRoutes: React.FC<AppRoutesProps> = ({
  hideQuestions,
  darkMode,
  setDarkMode,
}) => {
  return (
    <Router>
      <UpperBar
        hideQuestions={hideQuestions}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
      />
      <Routes>
        {/*  <Route path="" element={<NoPage />} /> */}
        <Route
          path="/:uuid/*"
          element={
            <FetchUuid>
              <Routes>
                <Route path="" element={<Home />} />
                <Route path="introduction" element={<PreQuestionnaire />} />
                <Route path="questions/:questionId" element={<Questions />} />
                <Route path="result" element={<Result />} />
                <Route path="notfound" element={<NotFound />} />
              </Routes>
            </FetchUuid>
          }
        />
        <Route path="" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="my-dashboard" element={<DashboardAdmin />} />
          <Route path="create-edit-interview" element={<CreateInterview />} />
          <Route path="chat-interview" element={<ChatInterview />} />
        </Route>
        <Route path="notfound" element={<NotFound />} />
      </Routes>
      <BottomBar />
    </Router>
  );
};

export default AppRoutes;
