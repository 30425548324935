import { Box, Button, Snackbar, TextField } from '@mui/material';
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./Home.module.scss";
import AnimationComponent from "../../components/AnimationComponent/AnimationComponent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { callAPI } from "../../services/interview.service";
import { useTranslation } from "react-i18next";

function Home() {
  const navigate = useNavigate();
  const uuid = useSelector((state: RootState) => state.uuidData.uuid);
  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get("invitationCode");

  const [invitationcodevalue, setInvitationcodevalue] = useState<string>('')
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
  const { t } = useTranslation();
  const startInterview = () => {
    if (invitationCode === null) {
      navigate(`/${uuid}/introduction?invitationCode=${invitationcodevalue}`)
      setOpenSnackbar(true)
    }
    else {
      navigate(`/${uuid}/introduction?invitationCode=${invitationCode}`);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const style = "right";
  return (
    <div className={styles.container}>
      <div className={styles.container_center}>
        <div className={styles.textWithBlur}>
          <h1> {t("general.welcome")}</h1>
          <p>
          {t("general.welcomemessage")}
          </p>
        </div>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message="Note archived"
        />
        <Box
          sx={{
            paddingTop: "12px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {invitationCode ?
            <Button variant="contained" color="primary" onClick={startInterview}>
                {t("general.buttontobegin")}
            </Button>
            :
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                placeholder='Enter invitation code'
                value={invitationcodevalue}
                InputProps={{
                  sx: {
                    textAlignLast: 'center',
                    '&::placeholder': {
                      textAlignLast: 'center',
                    },
                  },
                }}
                onChange={(e) => setInvitationcodevalue(e.target.value)}
                variant="outlined"
                fullWidth
              ></TextField>
              <br />
              <Button variant="contained" color="primary" onClick={startInterview} disabled={invitationcodevalue.length > 0 ? false : true} sx={{
                backgroundColor: invitationcodevalue.length > 0 ? 'lightgray' : 'primary.main',
                '&:hover': {
                  backgroundColor: invitationcodevalue.length > 0 ? 'lightgray' : 'primary.dark',
                },
              }}>
                 {t("general.buttontobegin")}
              </Button>


            </div>
          }

        </Box>
      </div>
      <AnimationComponent></AnimationComponent>
    </div>
  );
}

export default Home