import { Box, Button } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import styles from "./NoPage.module.scss";
import AnimationComponent from "../../components/AnimationComponent/AnimationComponent";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useTranslation } from "react-i18next";

function NotPage() {



  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.container_center}>
        <div className={styles.textWithBlur}>
          <h1>Interview GPT</h1>
          <p>Page not found :{")"}</p>
        </div>
      </div>
      <AnimationComponent></AnimationComponent>
    </div>
  );
}

export default NotPage;