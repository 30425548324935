import React from "react";
import { useMsal } from "@azure/msal-react";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const SignOutButton: React.FC = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: `${window.location.origin}`,
    });
  };

  return (
    <Tooltip title="Sign out">
      <IconButton onClick={handleLogout}>
        <LogoutIcon sx={{ marginLeft: "10px", color: "secondary" }}>
          Sign Out
        </LogoutIcon>
      </IconButton>
    </Tooltip>
  );
};

export default SignOutButton;
