import React, { useState } from 'react';

import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme, lightTheme } from "./theme";
import AppRoutes from "./routes/AppRoutes";
import { Container, CssBaseline, IconButton, Select, SelectChangeEvent } from "@mui/material";
import { styled } from "@mui/material/styles";

function App() {

  
  const [darkMode, setDarkMode] = useState(false);
  const [hideQuestions, setHideQuestions] = useState(false);


  const MainContent = styled("div")({
    position: "relative",
    zIndex: 1, // Ensures the content is above the app bars
  });




  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <MainContent>
        <AppRoutes
          hideQuestions={hideQuestions}
          darkMode={darkMode}
          setDarkMode={setDarkMode}
        ></AppRoutes>
      </MainContent>
    </ThemeProvider>
  );
}

export default App;
