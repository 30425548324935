import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "a35a2ad6-bf73-40d6-b9ed-9c0e23e0c0cb",
    authority:
      "https://login.microsoftonline.com/c2c095a8-a041-4c30-93db-8f6b6c07e3df",
    redirectUri: `${window.location.origin}`,
  },
  cache: {
    storeAuthStateInCookie: true,
    cacheLocation: "localStorage",
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ["api://1fe48190-55a3-4bc9-9a61-f9f28def9a96/User.Write"],
};
