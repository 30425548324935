import { createTheme, ThemeProvider, styled, PaletteOptions } from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#27496b', // Principal color
        },
        secondary: {
            main: '#dc004e', // Secondary color
        },
        error: {
            main: '#f44336', // Error color
        }
    },
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
        h1: {
            fontSize: '2rem',
            fontWeight: 500,
        },
        h2: {
            fontSize: '1.75rem',
            fontWeight: 500,
        },
        h3: {
            fontSize: '1.5rem',
            fontWeight: 500,
        },
        body1: {
            fontSize: '1rem',
            lineHeight: 1.5,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none', // Evitar que los botones estén en mayúsculas
                },
            },
        },
    },
});


interface CustomPalette extends PaletteOptions {
    customColors?: {
        primary: string;
        secondary: string;
        textLight: string;
    };
}


export const lightTheme = createTheme({

    palette: {
        mode: 'light'
        ,
        primary: {
            main: '#77A1D3', //test colors, error
        },
        secondary: {
            main: '#79CBCA',
        },
        text: {
            primary: '#27496b',
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },

    typography: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 16,
        h1: {
            fontSize: '2.5rem', // Example heading size
            [theme.breakpoints.down('sm')]: {
                fontSize: '2rem', // Adjust size for smaller screens
            },
        },
        body1: {
            fontSize: '1rem', // Example body text size
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem', // Adjust size for smaller screens
            },
        },
    },

    components: {
        MuiSelect: {
            styleOverrides: {
                root: {
                    // Customize the root styles of MuiSelect
                    color: '#27496b !important', // Text color
                    '&:hover': {
                        background: 'transparent'
                    },
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: 'white',
                    color: '#27496b'
                },
            }
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    background: 'white',
                    color: '#27496b',
                    '&:hover': {
                        background: 'revert'
                    },
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                outlined: {
                    backgroundImage: 'transparent',
                    margin: '10px',
                    textAlign: 'center',
                    transition: '0.5s',
                    backgroundSize: '200% auto',
                    color: '#6e7583',
                    borderRadius: '10px',
                    display: 'block',
                    border: 'none',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    '&:hover': {
                        border: 'none',
                        color: '#6e7583',
                        textDecoration: 'none',
                    },
                    [theme.breakpoints.up('sm')]: {
                        margin: '10px',
                        padding: '15px 45px', // Larger size for desktop
                    },
                    [theme.breakpoints.down('xs')]: {
                        margin: '10px',
                        padding: '15px 30px',  // Smaller size for mobile
                    },
                },
                contained: {
                    backgroundImage: 'linear-gradient(to right, #77A1D3 0%, #79CBCA  51%, #77A1D3  100%)',
                    margin: '10px',
                    textAlign: 'center',
                    transition: '0.5s',
                    backgroundSize: '200% auto',
                    color: 'white',
                    borderRadius: '10px',
                    display: 'block',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    '&:hover': {
                        backgroundPosition: 'right center', /* change the direction of the change here */
                        color: '#fff',
                        textDecoration: 'none',
                    },
                    [theme.breakpoints.up('sm')]: {
                        margin: '10px',
                        padding: '15px 45px', // Larger size for desktop
                    },
                    [theme.breakpoints.down('xs')]: {
                        margin: '10px',
                        padding: '15px 45px',  // Smaller size for mobile
                    },
                },
                text: {
                    backgroundColor: '#ea3535',
                    border: 'none',
                    color: 'white',
                    margin: '10px',
                    transition: '0.5s',
                    backgroundSize: '200% auto',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    '&:hover': {
                        backgroundPosition: 'right center', /* change the direction of the change here */
                        textDecoration: 'none',
                        color: 'white',
                        backgroundColor: '#ea3535d1',
                    },
                    [theme.breakpoints.up('sm')]: {
                        margin: '10px',
                        padding: '15px 45px', // Larger size for desktop
                    },
                    [theme.breakpoints.down('xs')]: {
                        margin: '10px',
                        padding: '15px 30px',  // Smaller size for mobile
                    },
                },
            },
        },
    },
})


export const darkTheme = createTheme({
    palette: {
        mode: 'dark'
    },
    components: {

        MuiButton: {
            styleOverrides: {
                outlined: {
                    backgroundImage: 'transparent',
                    margin: '10px',
                    textAlign: 'center',
                    transition: '0.5s',
                    backgroundSize: '200% auto',
                    color: '#6e7583',
                    borderRadius: '10px',
                    display: 'block',
                    border: 'none',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    '&:hover': {
                        border: 'none',
                        color: '#6e7583',
                        textDecoration: 'none',
                    },
                    [theme.breakpoints.up('sm')]: {

                        padding: '15px 45px', // Larger size for desktop
                    },
                    [theme.breakpoints.down('xs')]: {

                        padding: '15px 20px',  // Smaller size for mobile
                    },
                },
                contained: {
                    backgroundImage: 'linear-gradient(to right, #77A1D3 0%, #79CBCA  51%, #77A1D3  100%)',
                    margin: '10px',
                    textAlign: 'center',
                    transition: '0.5s',
                    backgroundSize: '200% auto',
                    color: 'white',
                    borderRadius: '10px',
                    display: 'block',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    '&:hover': {
                        backgroundPosition: 'right center', /* change the direction of the change here */
                        color: '#fff',
                        textDecoration: 'none',
                    },
                    [theme.breakpoints.up('sm')]: {

                        padding: '15px 45px', // Larger size for desktop
                    },
                    [theme.breakpoints.down('xs')]: {

                        padding: '15px 20px',  // Smaller size for mobile
                    },
                },
                text: {
                    backgroundColor: '#ea3535',
                    border: 'none',
                    color: 'white',
                    margin: '10px',
                    transition: '0.5s',
                    backgroundSize: '200% auto',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    '&:hover': {
                        backgroundPosition: 'right center', /* change the direction of the change here */
                        textDecoration: 'none',
                        color: 'white',
                        backgroundColor: '#ea3535d1',
                    },
                    [theme.breakpoints.up('sm')]: {

                        padding: '15px 45px', // Larger size for desktop
                    },
                    [theme.breakpoints.down('xs')]: {

                        padding: '15px 20px',  // Smaller size for mobile
                    },
                },
            },
        },
    },
})