import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./Result.module.scss";
import { Box, Button, TextField, CircularProgress, Alert } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { IInterviewState } from "../../models/IInterviewState";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { generateInterviewGPT } from "../../services/interview.service";
import { callAPI } from "../../services/interview.service";
import confeti from '../../assets/confeti.png'
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";

function Result() {
  const navigate = useNavigate();

  const [answerModel, setAnswerModel] = useState(
    "Response using model GPT - 4, the summary of your answers are the following"
  );
  const [answerByUser, setAnswerByUser] = useState("");
  const [responseInterview, setResponseInterview] = useState<string>("");
  const [congratulations, setCongratulations] = useState(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [finalPrompt, setFinalPrompt] = useState<string>("");
  const [messageError, setMessageError] = useState<boolean>(false);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get("invitationCode");
  const endpoint = "https://fa-np-tl-interviewai.azurewebsites.net/api";
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const responseAI = useSelector(
    (state: RootState) => state.interviewResult.responseText
  );

  const answers = useSelector(
    (state: RootState) => state.interviewAnswer.answers
  );

  const { data } = useSelector(
    (state: { interview: IInterviewState }) => state.interview
  );

  const sendInterview = async () => {
    try {
      const ResultUser = answerByUser !== responseInterview ? answerByUser : "";
      const ResultAi = responseInterview;
      const resultBody = {
        InvitationCode: invitationCode || "",
        ResultUser: ResultUser,
        ResultAi: ResultAi,
      };

      const response = await callAPI({
        method: "PUT",
        endpoint: `${endpoint}/public/result`,
        body: resultBody,
      });
      if (response !== null) {
        setCongratulations(true);
        setMessageError(false);
      } else {
        setMessageError(true);
        setCongratulations(false);
      }
      console.log("All answers saved successfully.", response);
    } catch (error) {
      console.error("Error saving answers:", error);
    }
  };

  const regenerateResponse = () => {
    setShowLoader(true);
    setResponseInterview(""); // Resetear la respuesta mientras se genera una nueva
    setAnswerByUser("");
    setIsStreaming(true);

    const iaGeneration = async () => {
      setShowLoader(true);
      setResponseInterview("");
      await generateInterviewGPT(
        finalPrompt,
        (response: any) => {
          setResponseInterview(response);
          setAnswerByUser(response);
        },
        setShowLoader
      );
      setIsStreaming(false);
      setResponseInterview((prev) => prev.replace(/[*#_]/g, ""));
      setAnswerByUser((prev) => prev.replace(/[*#_]/g, ""));
    };

    iaGeneration();
  };

  useEffect(() => {
    if (data !== null && answers !== null) {
      const userInterviewData = data!.Questions.map(
        (quest, index) =>
          `\n${index + 1}-quest:${quest.QuestionText}\n ans:${
            answers[quest.Id]
          }`
      );

      const prompt = `${
        data!.Prompt
      }. The content to be generated should not exceed 3 paragraphs. \n ${userInterviewData}`;
      console.log("prompt", prompt);
      setFinalPrompt(prompt);

      const openFunc = async () => {
        setShowLoader(true);
        setIsStreaming(true);
        await generateInterviewGPT(
          prompt,
          (response: any) => {
            setResponseInterview(response);
            setAnswerByUser(response);
          },
          setShowLoader
        );
        setIsStreaming(false);
        setResponseInterview((prev) => prev.replace(/[*#_]/g, ""));
        setAnswerByUser((prev) => prev.replace(/[*#_]/g, ""));
      };

      openFunc();
    }
  }, [data, answers]);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_center}>
        {messageError ? (
          <Alert severity="warning" onClose={() => setMessageError(false)}>
            {t("general.invalid")}
          </Alert>
        ) : null}

        {congratulations ? (
          <>
            {" "}
            <h1>{t("general.done")}</h1>{" "}
            <img
              src={confeti}
              alt="My Image Description"
              style={{ width: "5%", height: "auto" }}
            ></img>
          </>
        ) : (
          <>
            <h1> {t("general.done")}</h1>
            <p>{t("general.setdownload")}</p>
            {showLoader ? (
              <Box sx={{ display: "flex", marginBottom: 5 }}>
                <Loading
                  message={t("general.loadingmessage")}
                  size={50}
                  color="secondary"
                />
              </Box>
            ) : (
              <TextField
                value={answerByUser}
                onChange={(e) => setAnswerByUser(e.target.value)}
                variant="outlined"
                multiline
                minRows={5}
                maxRows={20}
                inputProps={{ cols: 40 }}
                fullWidth
                disabled={isStreaming}
              />
            )}

            <Box
              sx={{
                paddingTop: "12px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                className={styles.buttonBack}
                variant="outlined"
                onClick={goBack}
              >
                {t("general.back")}
              </Button>
              <Button
                variant="contained"
                sx={{ display: "flex", justifyContent: "center" }}
                onClick={regenerateResponse}
                disabled={isStreaming}
              >
                {t("general.regenerate")}
                <AutorenewIcon sx={{ marginLeft: 0.5 }} />
              </Button>
              <Button
                variant="contained"
                onClick={sendInterview}
                disabled={isStreaming}
              >
                {t("general.submit")}
              </Button>
            </Box>
          </>
        )}
      </div>
    </div>
  );
}

export default Result