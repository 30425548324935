import {configureStore} from '@reduxjs/toolkit'
import questionnaireReducer from './slices/questionsSlice' //exported by default
import answerQuestionReducer from './slices/answerQuestionSlice' //exported by default
import responseReducer from './slices/responseAISlice' //exported by default
import thunk from 'redux-thunk';
import uuidReducer from './slices/uuidSlice';

const store = configureStore({
    reducer: {
        interview : questionnaireReducer, //questions
        interviewAnswer : answerQuestionReducer,  //answers
        interviewResult : responseReducer,  //resultAI
        uuidData: uuidReducer, //uuid
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),

})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store