import React, { useState, useEffect } from "react";
import styles from "./CreateInterview.module.scss";
import {
  Box,
  Fab,
  IconButton,
  Paper,
  TextField,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { IQuestion } from "../../models/IQuestion";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { callAPI } from "../../services/interview.service";
import { useAuthToken } from "../../hooks/useAuthToken";
import { useNavigate, useSearchParams } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { createData } from "../../utils/tableUtil";
import { useTranslation } from "react-i18next";

function CreateInterview() {
  const [showQuestionField, setShowQuestionField] = useState(false);
  const [newQuestion, setNewQuestion] = useState<string>("");

  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [interviewName, setInterviewName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [prompt, setPrompt] = useState<string>("");
  const [emails, setEmails] = useState<string>("");
  const [existingInvitations, setExistingInvitations] = useState<any[]>([]);
  const [interviewUuid, setInterviewUuid] = useState<string | null>(null);
  const [existingQuestions, setExistingQuestions] = useState<IQuestion[]>([]);
  const [editQuestionId, setEditQuestionId] = useState<number | null>(null);
  const [editedQuestionText, setEditedQuestionText] = useState<string>("");
  const accessToken = useAuthToken();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const interviewId = searchParams.get("interviewId");
  const viewOnly = searchParams.get("viewOnly") === "true";
  const endpoint = "https://fa-np-tl-interviewai.azurewebsites.net/api";

  const { t } = useTranslation();
  useEffect(() => {
    const fetchInterviews = async () => {
      if (interviewId) {
        try {
          const options = {
            accessToken,
            method: "GET",
            endpoint: `${endpoint}/interviews?All=yes`,
          };
          const response = await callAPI(options);

          const interview = response.find(
            (item: any) => item.Id.toString() === interviewId
          );
          console.log("interview", interview);

          if (interview) {
            setInterviewName(interview.Title);
            setDescription(interview.Description);
            setPrompt(interview.Prompt);
            setQuestions(interview.Questions || []);
            setEmails(
              interview.Invitations.map((invite: any) => invite.Email).join(
                ", "
              )
            );
            setInterviewUuid(interview.Uuid);
            setExistingInvitations(interview.Invitations || []);
            setExistingQuestions(interview.Questions || []);
          }
        } catch (error) {
          console.error("Error fetching interviews:", error);
        }
      }
    };

    fetchInterviews();
  }, [interviewId, accessToken, endpoint]);

  const handleAddQuestion = () => {
    if (!viewOnly) {
      setShowQuestionField(true);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!viewOnly && e.key === "Enter" && newQuestion.trim()) {
      const newQuestionObject: IQuestion = {
        Id: questions.length + 1,
        InterviewId: 1,
        QuestionText: newQuestion.trim(),
        QuestionOrder: questions.length + 1,
        IsRequired: true,
      };
      setQuestions([...questions, newQuestionObject]);
      setNewQuestion("");
      setShowQuestionField(false);
    }
  };

  const saveAllQuestions = async (interviewId: number) => {
    if (viewOnly) return;
    try {
      const existingQuestionIds = existingQuestions.map((q) => q.Id);

      for (const existingQuestion of existingQuestions) {
        if (!questions.some((q) => q.Id === existingQuestion.Id)) {
          await callAPI({
            accessToken,
            endpoint: `${endpoint}/question`,
            method: "DELETE",
            body: { Id: existingQuestion.Id },
          });
          console.log(`Deleted question with Id: ${existingQuestion.Id}`);
        }
      }

      for (const question of questions) {
        const isExistingQuestion = existingQuestionIds.includes(question.Id);
        const questionData: any = {
          InterviewId: interviewId,
          QuestionText: question.QuestionText,
          QuestionOrder: question.QuestionOrder,
          IsRequired: question.IsRequired,
        };

        if (isExistingQuestion) {
          questionData.Id = question.Id;
        }

        console.log("questionData", questionData);

        const options = {
          accessToken,
          endpoint: `${endpoint}/question`,
          method: "PUT",
          body: questionData,
        };
        console.log("optionsQuestions", options);

        const responseQuestion = await callAPI(options);
        console.log("responseQuestion", responseQuestion);
      }
    } catch (error) {
      console.error("Error saving questions:", error);
    }
  };

  const saveInvitations = async (interviewId: number, Uuid: string) => {
    if (viewOnly) return;
    try {
      const emailList = Array.from(
        new Set(
          emails
            .split(",")
            .map((email) => email.trim())
            .filter((email) => email !== "")
        )
      );

      if (emailList.length === 0) {
        console.log("No emails provided, skipping invitations step.");
        return;
      }

      const existingEmails = existingInvitations.map(
        (invite: any) => invite.Email
      );

      for (const email of emailList) {
        if (!existingEmails.includes(email)) {
          const invitationData = {
            InvitationStatus: "pending",
            InterviewId: interviewId,
            Email: email,
          };

          const options = {
            accessToken,
            endpoint: `${endpoint}/invitation`,
            method: "PUT",
            body: invitationData,
          };

          const response = await callAPI(options);
          const invitationCode = response.InvitationCode;
          console.log("Added new invitation:", response);
          const link = `${window.location.origin}/${Uuid}?invitationCode=${invitationCode}`;

          const mailtoLink = `mailto:${email}?subject=Invitation%20to%20participate%20in%20a%20new%20Interview&body=Hello%20Dear%20User,%0A%0AYou%20have%20been%20invited%20to%20participate%20in%20an%20interview.%0A%0APlease%20use%20the%20following%20link%20to%20start:%0A%0A${link}%0A%0AThank%20you,%0AInterviewGPT%20Team`;
          console.log("mailtoLink", mailtoLink);

          window.location.href = mailtoLink;

          console.log("Generated invitation link:", link);
        }
      }

      for (const existingInvite of existingInvitations) {
        if (!emailList.includes(existingInvite.Email)) {
          await callAPI({
            accessToken,
            endpoint: `${endpoint}/invitation`,
            method: "DELETE",
            body: { Id: existingInvite.Id },
          });
          console.log(`Deleted invitation with Id: ${existingInvite.Id}`);
        }
      }
    } catch (error) {
      console.error("Error saving invitations:", error);
    }
  };

  const handleSaveInterview = async () => {
    if (viewOnly) return;
    const interviewData: any = {
      Title: interviewName,
      Description: description,
      Prompt: prompt,
      Model: "gpt-4o",
      Status: "active",
    };
    if (interviewUuid) {
      interviewData.Uuid = interviewUuid;
    }
    try {
      const options = {
        accessToken,
        endpoint: `${endpoint}/interview`,
        method: "PUT",
        body: interviewData,
      };
      console.log("accessToken", accessToken);
      if (accessToken) {
        const response = await callAPI(options);

        const interviewId = response.Id;
        const interviewUuid = response.Uuid;

        console.log("response", response);

        await saveAllQuestions(interviewId);

        await saveInvitations(interviewId, interviewUuid);

        navigate("/my-dashboard");
      }
    } catch (error) {
      console.error("Error saving interview:", error);
    }
  };

  const handleEditQuestion = (questionId: any) => {
    if (!viewOnly) {
      const questionToEdit = questions.find((q) => q.Id === questionId);
      if (questionToEdit) {
        setEditQuestionId(questionId);
        setEditedQuestionText(questionToEdit.QuestionText);
      }
    }
  };

  const handleSaveEditedQuestion = (questionId: number) => {
    if (!viewOnly) {
      setQuestions((prevQuestions) =>
        prevQuestions.map((question) =>
          question.Id === questionId
            ? { ...question, QuestionText: editedQuestionText }
            : question
        )
      );
      setEditQuestionId(null);
      setEditedQuestionText("");
    }
  };

  const handleCancelEdit = () => {
    if (!viewOnly) {
      setEditQuestionId(null);
      setEditedQuestionText("");
    }
  };

  const handleDeleteQuestion = (questionId: any) => {
    if (!viewOnly) {
      setQuestions(questions.filter((question) => question.Id !== questionId));
    }
  };

  const handleMoveQuestion = (questionId: any, action: string) => {
    if (!viewOnly) {
      setQuestions((prevQuestions) => {
        const index = prevQuestions.findIndex((q) => q.Id === questionId);

        if (index === -1) return prevQuestions;

        const newQuestions = [...prevQuestions];

        if (action === "up" && index > 0) {
          const temp = newQuestions[index - 1];
          newQuestions[index - 1] = newQuestions[index];
          newQuestions[index] = temp;
        } else if (action === "down" && index < newQuestions.length - 1) {
          const temp = newQuestions[index + 1];
          newQuestions[index + 1] = newQuestions[index];
          newQuestions[index] = temp;
        }

        return newQuestions.map((q, idx) => ({
          ...q,
          QuestionOrder: idx + 1,
        }));
      });
    }
  };

  const handleCopyInvitationLink = (
    invitationCode: string,
    interviewUuid: string
  ) => {
    const invitationLink = `${window.location.origin}/${interviewUuid}?invitationCode=${invitationCode}`;
    navigator.clipboard.writeText(invitationLink);
    console.log(`Copied to clipboard: ${invitationLink}`);
  };

  const handleGoBack = () => {
    navigate("/my-dashboard");
  };

  const handleEditMode = () => {
    navigate(`/create-edit-interview?interviewId=${interviewId}`);
  };

  return (
    <div className={styles.container}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          sx={{ position: "absolute", left: "130px" }}
          onClick={handleGoBack}
        >
          <ArrowBackIcon />
        </IconButton>

        <h2>
          {viewOnly
            ? t("general.admin.viewTitle")
            : interviewId
            ? t("general.admin.editTitle")
            : t("general.admin.CreateTitle")}
        </h2>
      </div>
      <Box className={styles.formContainer}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h6" className={styles.title}>
            {t("general.admin.interviewname")}
          </Typography>
          {viewOnly && (
            <IconButton onClick={handleEditMode}>
              <EditIcon />
            </IconButton>
          )}
        </div>

        <TextField
          label={t("general.admin.interviewname")}
          variant="standard"
          fullWidth
          margin="normal"
          className={styles.textField}
          value={interviewName}
          onChange={(e) => !viewOnly && setInterviewName(e.target.value)}
          disabled={viewOnly}
        />
        <TextField
          label={t("general.admin.description")}
          variant="standard"
          fullWidth
          margin="normal"
          multiline
          className={styles.textField}
          value={description}
          onChange={(e) => !viewOnly && setDescription(e.target.value)}
          disabled={viewOnly}
        />
        <TextField
          label="Prompt"
          variant="standard"
          fullWidth
          margin="normal"
          multiline
          className={styles.textField}
          value={prompt}
          onChange={(e) => !viewOnly && setPrompt(e.target.value)}
          disabled={viewOnly}
        />

        <Box className={styles.questionContainer}>
          <Typography variant="h6" className={styles.title}>
            {t("general.admin.questions")}
          </Typography>
          {!viewOnly && (
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              sx={{ margin: "20px" }}
              onClick={handleAddQuestion}
            >
              <AddIcon className={styles.addButton} />
            </Fab>
          )}
        </Box>
        {showQuestionField && !viewOnly && (
          <TextField
            label="Nombre de la Pregunta"
            variant="outlined"
            fullWidth
            margin="normal"
            className={styles.textField}
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        )}
        <Box className={styles.questionsList}>
          {questions
            .sort((a, b) => a.QuestionOrder - b.QuestionOrder)
            .map((question, index) => (
              <Paper key={question.Id} className={styles.questionItem}>
                <DragIndicatorIcon className={styles.dragIcon} />
                {editQuestionId === question.Id && !viewOnly ? (
                  <TextField
                    value={editedQuestionText}
                    onChange={(e) => setEditedQuestionText(e.target.value)}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className={styles.textField}
                  />
                ) : (
                  <Typography variant="body1" className={styles.questionText}>
                    {question.QuestionText}
                  </Typography>
                )}
                {!viewOnly && (
                  <>
                    {editQuestionId === question.Id ? (
                      <>
                        <IconButton
                          onClick={() => handleSaveEditedQuestion(question.Id)}
                        >
                          <SaveIcon />
                        </IconButton>
                        <IconButton onClick={handleCancelEdit}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton
                          onClick={() => handleEditQuestion(question.Id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteQuestion(question.Id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                    <IconButton
                      onClick={() => handleMoveQuestion(question.Id, "up")}
                      disabled={index === 0}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleMoveQuestion(question.Id, "down")}
                      disabled={index === questions.length - 1}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                  </>
                )}
              </Paper>
            ))}
          <Box className={styles.questionContainer}>
            <Typography variant="h6" className={styles.title}>
              {t("general.admin.invitationEmail")}
            </Typography>
            <TextField
              label={t("general.admin.emailseparate")}
              variant="outlined"
              fullWidth
              margin="normal"
              className={styles.textField}
              value={emails}
              onChange={(e) => !viewOnly && setEmails(e.target.value)}
              disabled={viewOnly}
            />
          </Box>
          <br />
          {interviewId ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell align="right">
                      {" "}
                      {t("general.admin.status")}
                    </TableCell>
                    <TableCell align="right">Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {existingInvitations.map((invitation) => (
                    <TableRow
                      key={invitation.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {invitation.Email}
                      </TableCell>
                      <TableCell align="right">
                        {invitation.InvitationStatus}
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Copy invitation link">
                          <IconButton
                            onClick={() =>
                              handleCopyInvitationLink(
                                invitation.InvitationCode,
                                interviewUuid || ""
                              )
                            }
                            size="small"
                          >
                            <ContentCopyIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            {!viewOnly && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveInterview}
                style={{ marginRight: "10px" }}
              >
                {t("general.admin.save")}
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleGoBack}
            >
              {viewOnly ? t("general.admin.back") : t("general.admin.cancel")}
            </Button>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default CreateInterview;
