import { AppBar, Button, IconButton, Toolbar } from '@mui/material'
import React from 'react'

function BottomBar() {
    return (
        <AppBar position="fixed" color="primary" style={{ top: 'auto', bottom: 0, fontSize: '12px', background: 'linear-gradient(to right, #77A1D3 0%, #79CBCA  51%, #77A1D3  100%)'}}>
            <Toolbar style={{ justifyContent: 'center' }}>
                <p>Interview GPT powered by <a href='' style={{ fontWeight: 'bold', textDecoration: 'none', color: 'inherit' }}>TechLab</a></p>

            </Toolbar>
        </AppBar>
    )
}

export default BottomBar