import React, { useState, useEffect } from "react";
import styles from "./DashboardAdmin.module.scss";
import TableInterview from "../../components/TableInterview";
import { Box, Button, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { callAPI } from "../../services/interview.service";
import { useAuthToken } from "../../hooks/useAuthToken";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";

function DashboardAdmin() {
  const [interviews, setInterviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updateTable, setUpdateTable] = useState(false);
  const accessToken = useAuthToken();

  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    const fetchInterviews = async () => {
      try {
        const options = {
          accessToken,
          endpoint: `https://fa-np-tl-interviewai.azurewebsites.net/api/interviews?All=yes`,
          method: "GET",
        };

        if (accessToken || updateTable) {
          setLoading(true)
          const response = await callAPI(options);
          console.log("response", response);
          setInterviews(response);
          setLoading(false);
          setUpdateTable(false);
        }
      } catch (err) {
        console.error("Error getting interviews:", err);
        setUpdateTable(false);
        //setError(err.text)
      }
    };

    fetchInterviews();
  }, [accessToken, updateTable]);

  const createNewInterview = () => {
    navigate("/create-edit-interview");
  };

  if (!accessToken) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <div className={styles.container}>
      <h2>{t("general.admin.Dashboard")}</h2>
      <div className={styles.summary}>
        <div className={styles.summarygroup}>
          
        <div className={styles.summarycount}>
            {interviews ? interviews.length : 0}
            <Chip label={t("general.admin.active")}variant="outlined" />
          </div>
          <Button
            variant="contained"
            color="primary"
            style={{margin: "25px 9px"}}
            onClick={createNewInterview}
          >
              {t("general.admin.newIntervieplus")}
          </Button>
    
        </div>
        <br/>
        {loading ? ( <Box sx={{ display: "flex", justifyContent: "center" }}><Loading message={t("general.loadingmessage")} size={50} color="secondary" /> </Box>) : (
          <TableInterview interviews={interviews} setUpdateTable={setUpdateTable}/>
        )}
      </div>
    </div>
  );
}

export default DashboardAdmin;
