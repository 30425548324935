import React from 'react'
import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { HeadTable, IInterviewItem } from '../models/IInterviewTable';

import { useTranslation } from "react-i18next";





function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IInterviewItem
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function HeadTableComponent(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const { t } = useTranslation();
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
    
    
const headCells: readonly HeadTable[] = [
  {
    id: "Title",
    numeric: false,
    disablePadding: false,
    label: t("general.admin.title"),
  },
  {
    id: "Description",
    numeric: false,
    disablePadding: true,
    label:  t("general.admin.description"),
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label:  t("general.admin.status"),
  },
  {
    id: "CreatedAt",
    numeric: false,
    disablePadding: false,
    label:  t("general.admin.CreatedAt"),
  },
  {
    id: "CreatedBy",
    numeric: false,
    disablePadding: false,
    label:  t("general.admin.CreatedBy"),
  },
  {
    id: "InvitationEmails",
    numeric: false,
    disablePadding: false,
    label:  t("general.admin.invitationEmail"),
  },
];
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default HeadTableComponent