import { IconButton, Select, SelectChangeEvent } from '@mui/material'
import React, { useState } from 'react'

import { useTranslation } from 'react-i18next';

function ChangeLanguage() {

    const { i18n } = useTranslation();
    const [languageSelect, setLanguageSelect] = useState<string | null>(
        i18n.language
    );


    const handleChangeLanguage = (event: SelectChangeEvent<string>) => {
        const language = event.target.value;
        i18n.changeLanguage(language);
        localStorage.setItem("lng", language);
        setLanguageSelect(language);
    };

    return (
        <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            color="inherit"
            onClick={() => handleChangeLanguage}
        >
            <Select
                native
                defaultValue={i18n.language || "es"}
                onChange={handleChangeLanguage}
                sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 1 },
                    color: "#fff",
                }}
            >
                <option style={{ color: "black" }} value="en">
                    English
                </option>
                <option style={{ color: "black" }} value="es">
                    Español
                </option>
            </Select>
        </IconButton>
    )
}

export default ChangeLanguage