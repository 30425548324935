
import { createSlice } from '@reduxjs/toolkit';

interface DataState {
    uuid: string | null;
}

const initialState: DataState = {
    uuid: null,
};

const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setUuid: (state, action) => {
            state.uuid = action.payload;
        },
    },
});

export const { setUuid } = dataSlice.actions;
export default dataSlice.reducer;