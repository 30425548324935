import { Box, Button } from '@mui/material';
import React from 'react'
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./PreQuestionnaire.module.scss";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { fetchQuestions } from '../../store/slices/questionsSlice';
import { IInterviewState } from '../../models/IInterviewState';

function PreQuestionnaire() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get("invitationCode");

  const uuid = useSelector((state: RootState) => state.uuidData.uuid);
  const { t } = useTranslation();
  const goBack = () => {
    navigate(-1);
  };

  const dispatch = useDispatch();

  const startInterview = () => {
    if (uuid !== null && invitationCode !== null) {
      navigate(`/${uuid}/questions/1?invitationCode=${invitationCode}`);
      dispatch(fetchQuestions({ uuid, invitationCode }))
    }


  };

  return (
    <div className={styles.container}>
      <div className={styles.container_center}>
        <h2> {t("general.instrucc")} </h2>
        <p>
          {t("general.instruccTextThank1")} <HeadphonesOutlinedIcon /> {t("general.instruccTextThank2")} <KeyboardVoiceOutlinedIcon /> .
        </p>
        <Box
          sx={{
            paddingTop: "12px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Button
            className={styles.buttonBack}
            variant="outlined"
            onClick={goBack}
          >
            {t("general.back")}
          </Button>
          <Button variant="contained" onClick={startInterview}>
            {t("general.next")}
          </Button>
        </Box>
      </div>
    </div>
  );
}

export default PreQuestionnaire