import { useMsal, useAccount } from "@azure/msal-react";
import { useState, useEffect, useCallback } from "react";
import { acquireToken } from "../utils/authUtils";

export const useAuthToken = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0]);

  const [accessToken, setAccessToken] = useState<string | null>(null);

  const getToken = useCallback(async () => {
    if (account) {
      try {
        const token = await acquireToken(account);
        setAccessToken(token);
      } catch (error) {
        console.error("Error acquiring token:", error);
      }
    }
  }, [account]);

  useEffect(() => {
    if (inProgress === "none" && account) {
      getToken();
    }
  }, [inProgress, account, getToken]);

  return accessToken;
};
