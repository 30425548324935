import { config } from "../../config";
import {
  Box,
  FormControl,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect, useRef, useCallback } from "react";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import styles from "./ChatInterview.module.scss";
import { callAPI, chatIA } from "../../services/interview.service";
import { useAuthToken } from "../../hooks/useAuthToken";
import { useNavigate, useSearchParams } from "react-router-dom";
import TextConverter from "../../utils/TextConverter";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const endpoint = "https://fa-np-tl-interviewai.azurewebsites.net/api";

function ChatInterview() {
  const [chatHistory, setChatHistory] = useState<
    Array<{ role: string; content: string; isComplete: boolean }>
  >([]);
  const [inputUser, setInputUser] = useState<string>("");
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [interviewData, setInterviewData] = useState<any>(null);
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchParams] = useSearchParams();
  const interviewId = searchParams.get("interviewId");
  const messageHistoryRef = useRef<Array<{ role: string; content: string }>>(
    []
  );
  const accessToken = useAuthToken();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchInterviewData = async () => {
      if (interviewId) {
        try {
          const options = {
            accessToken,
            method: "GET",
            endpoint: `${endpoint}/interviews?All=yes`,
          };
          const response = await callAPI(options);

          const interview = response.find(
            (item: any) => item.Id.toString() === interviewId
          );
          console.log("interview", interview);
          setInterviewData(interview);

          // Add initial AI greeting
          const initialGreeting = t("general.admin.welcomechat", {
            interviewTitle: interview.Title,
          });
          const initialMessage = {
            role: "assistant",
            content: initialGreeting,
            isComplete: true,
          };
          setChatHistory([initialMessage]);
          messageHistoryRef.current.push(initialMessage);
        } catch (error) {
          console.error("Error fetching interviews:", error);
        }
      }
    };
    fetchInterviewData();
  }, [interviewId, accessToken]);

  const scrollToBottom = useCallback(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, scrollToBottom]);

  const handleSendMessage = async () => {
    if (inputUser.trim() === "" || isStreaming) return;

    const newUserMessage = {
      role: "user",
      content: inputUser,
      isComplete: true,
    };
    setChatHistory((prev) => [...prev, newUserMessage]);
    setInputUser("");
    setIsStreaming(true);

    try {
      const streamingMessage = {
        role: "assistant",
        content: "",
        isComplete: false,
      };
      setChatHistory((prev) => [...prev, streamingMessage]);

      messageHistoryRef.current.push(newUserMessage);

      let accumulatedResponse = "";

      const systemMessage =
        "Please limit your responses to answering the user's questions based solely on the context of the interview that has been provided to you. Do not provide information or opinions outside of this specific interview context. Be friendly and highly professional in your responses. If the user asks for information unrelated to the interview or requests something outside the scope of the interview, kindly inform them that you cannot respond to that and politely redirect them to ask questions about the interview.";

      await chatIA(
        inputUser,
        interviewData,
        messageHistoryRef.current,
        (response: string) => {
          accumulatedResponse += response;
          setChatHistory((prev) => {
            const updatedHistory = [...prev];
            updatedHistory[updatedHistory.length - 1] = {
              role: "assistant",
              content: accumulatedResponse,
              isComplete: false,
            };
            return updatedHistory;
          });
        },
        setIsStreaming,
        systemMessage
      );

      console.log("response", accumulatedResponse);

      setChatHistory((prev) => {
        const updatedHistory = [...prev];
        updatedHistory[updatedHistory.length - 1] = {
          role: "assistant",
          content: accumulatedResponse,
          isComplete: true,
        };
        return updatedHistory;
      });

      const finalAssistantMessage = {
        role: "assistant",
        content: accumulatedResponse,
      };
      messageHistoryRef.current.push(finalAssistantMessage);

      if (messageHistoryRef.current.length > 20) {
        messageHistoryRef.current = messageHistoryRef.current.slice(-20);
      }
    } catch (error) {
      console.error("Error in chat:", error);
      const errorMessage = {
        role: "assistant",
        content: "Sorry, I encountered an error. Please try again.",
        isComplete: true,
      };
      setChatHistory((prev) => [...prev, errorMessage]);
    } finally {
      setIsStreaming(false);
      if (inputRef.current) {
        inputRef.current.focus();
      }
      scrollToBottom();
    }
  };

  const renderChatMessage = useCallback(
    (
      message: { role: string; content: string; isComplete: boolean },
      index: number
    ) => {
      return (
        <div key={index} style={{ textAlign: "left", marginBottom: "10px" }}>
          <span style={{ display: "flex", alignItems: "baseline" }}>
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                alignContent: "center",
                marginRight: "10px",
              }}
            >
              {message.role === "assistant" ? "Assistant:" : "User:"}
            </div>
            {!message.isComplete ? (
              <>
                <TextConverter text={message.content} />
                <CircularProgress size={10} style={{ marginLeft: "5px" }} />
              </>
            ) : (
              <TextConverter text={message.content} />
            )}
          </span>
        </div>
      );
    },
    []
  );

  const handleGoBack = () => {
    navigate("/my-dashboard");
  };

  return (
    <section className={styles.container}>
      <div className={styles.container_center}>
        <IconButton
          sx={{ position: "absolute", left: "130px" }}
          onClick={handleGoBack}
        >
          <ArrowBackIcon />
        </IconButton>
        <h3>Chat Interview GPT</h3>
        <Box className={styles.chat} sx={{ height: "70%" }}>
          <FormControl
            fullWidth
            sx={{ height: "100%", alignItems: "flex-start" }}
          >
            <TextField
              value=""
              variant="outlined"
              multiline
              fullWidth
              inputProps={{
                style: {
                  height: "100%",
                  overflowY: "auto",
                  whiteSpace: "pre-wrap",
                },
              }}
              sx={{
                height: "100%",
                "& .MuiInputBase-root": {
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                },
                "& .MuiOutlinedInput-root": {
                  height: "100%",
                },
                "& .MuiOutlinedInput-input": {
                  height: "100% !important",
                  overflowY: "auto !important",
                },
              }}
              InputProps={{
                readOnly: true,
                inputComponent: () => (
                  <div
                    ref={chatBoxRef}
                    style={{
                      height: "100%",
                      overflowY: "auto",
                      padding: "16px",
                      width: "100%",
                      scrollBehavior: "smooth",
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                  >
                    <div>
                      {chatHistory.map((message, index) =>
                        renderChatMessage(message, index)
                      )}
                    </div>
                  </div>
                ),
              }}
            />
          </FormControl>
        </Box>
        <div className={styles.container_input}>
          <TextField
            value={inputUser}
            onChange={(e) => setInputUser(e.target.value)}
            variant="outlined"
            multiline
            inputProps={{ cols: 40, ref: inputRef }}
            fullWidth
            placeholder="Ask a question about the interview"
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            disabled={isStreaming}
          />
          <div
            className={styles.icon}
            onClick={handleSendMessage}
            style={{
              opacity: isStreaming ? 0.5 : 1,
              cursor: isStreaming ? "not-allowed" : "pointer",
            }}
          >
            <SendOutlinedIcon />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChatInterview;
