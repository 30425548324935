import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface LoadingProps {
    message?: string; // Optional message to display below the loader
    size?: number; // Optional size for the CircularProgress
    color?: 'primary' | 'secondary' | 'inherit'; // Optional color for the CircularProgress
    marginBottom?: number; // Optional margin for spacing
}

const Loading: React.FC<LoadingProps> = ({
    message = "Loading...", // Default message
    size = 40, // Default size for the loader
    color = "primary", // Default color
    marginBottom = 5, // Default marginBottom
}) => {
    return (
        <Box
            sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom }}
        >
            <CircularProgress size={size} color={color} />
            <Typography sx={{ marginTop: 2 }} variant="body2">
                {message}
            </Typography>
        </Box>
    );
};

export default Loading;
