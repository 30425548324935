import React, { useEffect, useState } from "react";
import { IQuestion } from "../../models/IQuestion";
import { Box, Button, IconButton, TextField } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { saveAnswer } from "../../store/slices/answerQuestionSlice";

import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import { Mic, MicOff } from "@mui/icons-material";

import SendIcon from "@mui/icons-material/Send";
import styles from "./Questionnaire.module.scss";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { fetchQuestions } from "../../store/slices/questionsSlice";
import { IInterviewState } from "../../models/IInterviewState";
import { callAPI } from "../../services/interview.service";
import { useTranslation } from "react-i18next";


function Questions() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const uuid = useSelector((state: RootState) => state.uuidData.uuid);
  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get("invitationCode");
  const endpoint = "https://fa-np-tl-interviewai.azurewebsites.net/api";
  const { t } = useTranslation();
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const { data, loading, error } = useSelector(
    (state: { interview: IInterviewState }) => state.interview
  );
  const { questionId } = useParams<{ questionId: string }>();

  const answers = useSelector(
    (state: RootState) => state.interviewAnswer.answers
  );

  const currentQuestionIndex = parseInt(questionId || "") - 1;
  const currentQuestion: IQuestion | undefined =
    data?.Questions[currentQuestionIndex];

  const [answer, setAnswer] = useState<string>("");
  const [storedAnswers, setStoredAnswers] = useState<{ [key: number]: any }>(
    {}
  );
  const [isDisabled, setIsDisabled] = useState(true);


  const handleToggle = () => {
    setIsDisabled(!isDisabled);
  };



  useEffect(() => {
    setAnswer(transcript);
  }, [transcript]);

  useEffect(() => {
    const fetchStoredAnswers = async () => {
      try {
        const response = await callAPI({
          method: "GET",
          endpoint: `${endpoint}/public/responses?InvitationCode=${invitationCode}`,
        });

        console.log("responseGET", response);
        const answersData = response.reduce((acc: any, ans: any) => {
          acc[ans.InterviewQuestionId] = ans;
          return acc;
        }, {});

        setStoredAnswers(answersData);
      } catch (error) {
        console.error("Error fetching stored answers:", error);
      }
    };

    fetchStoredAnswers();
  }, [invitationCode, endpoint]);

  useEffect(() => {
    if (currentQuestion && currentQuestion.Id !== undefined) {
      const storedAnswer = storedAnswers[currentQuestion.Id];
      const reduxAnswer = answers[currentQuestion.Id];

      // Prioridad de respuestas:
      // 1. Respuesta modificada (Redux)
      // 2. Respuesta almacenada (API)
      if (reduxAnswer !== undefined) {
        setAnswer(reduxAnswer);
      } else if (storedAnswer) {
        setAnswer(storedAnswer.ResponseText);
        dispatch(
          saveAnswer({
            questionId: currentQuestion.Id,
            answer: storedAnswer.ResponseText,
          })
        );
      } else {
        setAnswer("");
      }
    }
  }, [currentQuestion, answers, storedAnswers, dispatch]);

  const saveAllAnswers = async (
    currentAnswer: string,
    currentQuestionId: number
  ) => {
    const updatedAnswers = {
      ...answers,
      [currentQuestionId]: currentAnswer,
    };

    console.log("updatedAnswers", updatedAnswers);
    try {
      for (const questionId in updatedAnswers) {
        const responseText = updatedAnswers[questionId];
        const storedAnswer = storedAnswers[questionId];
        const responseBody = {
          InvitationCode: invitationCode || "",
          InterviewQuestionId: parseInt(questionId),
          ResponseText: responseText,
          ...(storedAnswer?.Id && { Id: storedAnswer.Id }),
        };

        await callAPI({
          method: "PUT",
          endpoint: `${endpoint}/public/response`,
          body: responseBody,
        });
      }

      console.log("All answers saved successfully.");
    } catch (error) {
      console.error("Error saving answers:", error);
    }
  };

  const handleNextQuestion = async () => {
    if (!currentQuestion) return;
    resetTranscript();

    dispatch(
      saveAnswer({
        questionId: currentQuestion.Id,
        answer,
      })
    );

    if (currentQuestionIndex < (data?.Questions.length || 0) - 1) {
      navigate(
        `/${uuid}/questions/${currentQuestionIndex + 2
        }?invitationCode=${invitationCode}`
      );
    } else {
      await saveAllAnswers(answer, currentQuestion.Id);
      navigate(`/${uuid}/Result?invitationCode=${invitationCode}`);
    }
  };

  const goBack = () => {
    dispatch(
      saveAnswer({
        questionId: currentQuestion?.Id!,
        answer,
      })
    );
    navigate(-1);
  };

  const handleSpeak = (text: string) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = "es-419"
      window.speechSynthesis.speak(utterance);
      return null
    } else {
      alert('Lo siento, tu navegador no soporta esta característica.');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_center}>
        <div style={{ marginBottom: "40px" }}>
          <p>
            {t("general.question")} {currentQuestionIndex + 1}
            {/* {data?.Questions[currentQuestionIndex].Id} */}
          </p>
          <h2>
            {data?.Questions[currentQuestionIndex].QuestionText}{" "}
            <IconButton onClick={() => handleSpeak(data?.Questions[currentQuestionIndex].QuestionText || '')}>
              <HeadphonesOutlinedIcon />{" "}
            </IconButton>

          </h2>
          <p>{t("general.useMic")}</p>
          <Button
            variant="text"
            className={
              listening ? styles.button_stoprecording : styles.button_recording
            }
            onClick={() => {
              if (!listening)
                SpeechRecognition.startListening({
                  continuous: true,
                  language: "es-CO",
                });
              else SpeechRecognition.stopListening();
            }}
            startIcon={
              listening ? (
                <MicOff className={styles.icon_button} />
              ) : (
                <Mic className={styles.icon_button} />
              )
            }
          >
            {listening ? t("general.stopcapture") : t("general.capture")}
          </Button>
        </div>
        <TextField
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          variant="outlined"
          multiline
          minRows={5}
          maxRows={12}
          inputProps={{ cols: 40 }}
          fullWidth
        ></TextField>

        <Box
          sx={{
            paddingTop: "12px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Button
            className={styles.buttonBack}
            variant="outlined"
            onClick={goBack}
          >
            {t("general.back")}
          </Button>
          {currentQuestionIndex < (data?.Questions.length || 0) - 1 ? (
            <Button variant="contained" onClick={handleNextQuestion} disabled={data?.Questions[currentQuestionIndex].IsRequired ? (answer.length > 0 ? false : true) : false} sx={{
              backgroundColor: isDisabled ? 'lightgray' : 'primary.main',
              '&:hover': {
                backgroundColor: isDisabled ? 'lightgray' : 'primary.dark',
              },
            }}>
              {t("general.next")}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleNextQuestion}
              disabled={data?.Questions[currentQuestionIndex].IsRequired ? (answer.length > 0 ? false : true) : false} sx={{
                backgroundColor: isDisabled ? 'lightgray' : 'primary.main',
                '&:hover': {
                  backgroundColor: isDisabled ? 'lightgray' : 'primary.dark',
                },
              }}
            >
              {t("general.generate")}
            </Button>
          )}
        </Box>
      </div>
    </div>
  );
}

export default Questions;
