import React from 'react'
import styles from "./Login.module.scss";
import { Box, Button } from '@mui/material';
import AnimationComponent from '../../components/AnimationComponent/AnimationComponent';
import { useNavigate } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useTranslation } from "react-i18next";

function Login() {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { t } = useTranslation();
  function handleLogin() {
    instance
      .loginPopup(loginRequest)
      .then((response: any) => {
        console.log(response);
        navigate(`/my-dashboard`);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  return (
    <div className={styles.container}>
      <div className={styles.container_center}>
        <div className={styles.textWithBlur}>
          <h1>{t("general.admin.login")}</h1>
          <p>{t("general.welcomemessage")}</p>
        </div>
        <Box
          sx={{
            paddingTop: "12px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Button variant="contained" color="primary" onClick={handleLogin}>
          {t("general.admin.Authenticat")}
          </Button>
        </Box>
      </div>
      <AnimationComponent></AnimationComponent>
    </div>
  );
}

export default Login