
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInterview, IQuestion } from '../../models/IQuestion';
import { callAPI } from "../../services/interview.service";
import { IInterviewState } from "../../models/IInterviewState";

const initialState: IInterviewState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchQuestions = createAsyncThunk<
  IInterview,
  { uuid: string; invitationCode: string },
  { rejectValue: string }
>("get/interview", async ({ uuid, invitationCode }, { rejectWithValue }) => {
  try {
    const options = {
      endpoint: `https://fa-np-tl-interviewai.azurewebsites.net/api/public/interview?Uuid=${uuid}&InvitationCode=${invitationCode}`,
      method: "GET",
    };
    const response = await callAPI(options);
    if (!response || response.error) {
      return rejectWithValue("Invalid UUID or Invitation Code");
    }
    console.log("response", response);
    return response;
  } catch (error) {
    console.error("Error fetching interview data:", error);
    return rejectWithValue("Failed to fetch interview");
  }
});



const interviewSlice = createSlice({
    name: 'interview',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchQuestions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchQuestions.fulfilled, (state, action: PayloadAction<IInterview>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchQuestions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch interview';
            });
    }

})



export default interviewSlice.reducer