import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest, msalInstance } from "../authConfig";

export const acquireToken = async (account: AccountInfo | null) => {
  if (!account) {
    throw new Error("No account information found.");
  }

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      const response = await msalInstance.acquireTokenPopup({
        ...loginRequest,
        account: account,
      });
      return response.accessToken;
    } else {
      console.error(error);
      throw error;
    }
  }
};
