import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./i18n/en.json";
import translationES from "./i18n/es.json";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
const language = "en";

const resources = {
    en: {
        translation: translationEN,
    },
    es: {
        translation: translationES,
    }
};
i18n.use(initReactI18next).init({
    lng: language,
    resources,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
});

export default i18n;
