import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInterview, IQuestion } from '../../models/IQuestion';
import { generateInterviewGPTo } from "../../services/interview.service";
import { IResponseState } from '../../models/IResponseState';


const initialState: IResponseState = {
    responseText: '' ,
    loading: false,
    error: null,
};


const responseSlice = createSlice({
    name: 'AIresponse',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(generateInterviewGPTo.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.responseText = '';
            })
            .addCase(generateInterviewGPTo.fulfilled, (state, action) => {
                state.loading = false;
                state.responseText = action.payload ?? '';
            })
            .addCase(generateInterviewGPTo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch AI response';
            });
    }

})



export default responseSlice.reducer