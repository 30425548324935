import React from 'react'
import styles from "./AnimationComponent.module.scss";





const AnimationComponent: React.FC = () => {

    const circleBlue = Array(200).fill(null);


    return (
        <div className={styles.container}>
            {circleBlue.map((_, index) => (
                <div key={index} className={styles.circle_container}>
                    <div className={styles.circle}></div>
                </div>
            ))}
        </div>
    )

}




export default AnimationComponent
