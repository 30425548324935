
import React, { useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuestions } from "../store/slices/questionsSlice";
import { setUuid } from "../store/slices/uuidSlice";
import { RootState } from "../store/store";

const FetchUuid: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { uuid } = useParams<{ uuid: string }>();
  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get("invitationCode");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error } = useSelector((state: RootState) => state.interview);

  useEffect(() => {
    if (uuid && invitationCode) {
      /* dispatch(fetchQuestions({ uuid, invitationCode })); */
      dispatch(setUuid(uuid));
    }
  }, [uuid, invitationCode, dispatch]);

  useEffect(() => {
    if (error) {
      navigate("/notfound");
    }
  }, [error, navigate]);

  return <>
    {children}
  </>;
};

export default FetchUuid;