import { createAsyncThunk } from "@reduxjs/toolkit";

const BASE_URL = "https://fa-np-tl-interviewai.azurewebsites.net/api/";

interface CallApiParams {
  accessToken?: string | null;
  endpoint: string;
  method?: string;
  body?: any;
}

export async function callAPI(params: CallApiParams) {
  console.log("params", params);
  const { accessToken, endpoint, method = "GET", body = null } = params;

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  if (accessToken) {
    headers.append("Authorization", `Bearer ${accessToken}`);
  }

  const options = {
    method: method,
    headers,
    body: body ? JSON.stringify(body) : null,
  };

  console.log("options", options);

  try {
    const response = await fetch(endpoint, options);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const contentType = response.headers.get("Content-Type");

    if (contentType && contentType.includes("application/json")) {
      return await response.json();
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function generateInterviewGPT(
  prompt: string,
  setResponse: any,
  setLoader: any
) {
  try {
    const response = await fetch(
      `https://fa-np-tl-chatinterview.azurewebsites.net/api/interview-gpt-openai?`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          temperature: 0.5,
          prompt: prompt,
        }),
      }
    );

    if (response.body !== null) {
      setLoader(false);
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunk = decoder.decode(value, { stream: true });
        setResponse((prevData: string) => prevData + chunk);
      }
    }
  } catch (error) {
    console.error("Error while streaming:", error);
    throw error;
  }
}

export async function chatIA(
  inputUser: string,
  interviewData: any,
  messageHistory: any,
  setResponse: any,
  setLoader: any,
  systemMessage?: string
) {
  try {
    const response = await fetch(
      `https://fa-np-tl-chatinterview.azurewebsites.net/api/chat_ia_interview?`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          temperature: 0.5,
          inputUser,
          interviewData,
          messageHistory,
          systemMessage: systemMessage || undefined,
        }),
      }
    );

    if (response.body !== null) {
      setLoader(false);
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunk = decoder.decode(value, { stream: true });
        console.log("chunk", chunk);
        setResponse(chunk);
      }
    }
  } catch (error) {
    console.error("Error while streaming:", error);
    throw error;
  }
}

export const generateInterviewGPTo = createAsyncThunk(
  "post/response",
  async (prompt: string, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://fa-np-tl-chatinterview.azurewebsites.net/api/interview-gpt-openai?`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            temperature: 0.5,
            prompt: prompt,
          }),
        }
      );

      if (response.body !== null) {
        const reader = response.body.getReader();

        const decoder = new TextDecoder();
        let done = false;
        let chunk = "";

        while (!done) {
          const { value, done: doneReading } = await reader.read();

          done = doneReading;
          chunk = decoder.decode(value, { stream: true });
        }
        return chunk;
      }
    } catch (error: any) {
      rejectWithValue(error.message);
      console.error("Error fetching interview data:", error);
    }
  }
);

