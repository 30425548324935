import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAnswer } from '../../models/IAnswer';
import { loadState, saveState } from '../../utils/localstorage';



const initialState: IAnswer = {
  answers: {},
};

const answerQuestionSlice = createSlice({
  name: "interview",
  initialState,
  reducers: {
    saveAnswer: (
      state,
      action: PayloadAction<{ questionId: number; answer: string }>
    ) => {
      const { questionId, answer } = action.payload;
      state.answers[questionId] = answer;

      //saveState('questionAnswer', state)
    },
  },
});


export const { saveAnswer } = answerQuestionSlice.actions

export default answerQuestionSlice.reducer